import { useRef, forwardRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Title, SubTitle, Toggle, LoadHandler } from '../Components/Components';
import { strArrayToText } from '../NonJSX/DataHandler';
import { CookieAllowed, UpdateConfig } from '../NonJSX/CookieConfig';
import { useGetJSON } from "../NonJSX/DataHandler.js";


import '../../CSS/Pages/Datenschutz.css';
import { SplitLine } from '../Components/StylingComponents.js';


var subsectionsRefs;

/**
 * Generates the body of the Datenschutz-Webpage
 * @returns The page-design as JSX
 */
export default function Datenschutz(props){
    document.title = "Datenschutz - FBG Bremen";
    
    let { scrollTo } = useParams();
    useEffect(() => {
        if(scrollTo && subsectionsRefs.current[scrollTo]) ScrollToParagraph(scrollTo);
    });

    subsectionsRefs = useRef([]);

    const data = useGetJSON("Data/datenschutz.json");

    let subsections = [];

    if(props.showSettings){
        subsections.push({
            title: "Einstellungen",
            obj: <SettingsInterface key="Einstellungen" ref={r => subsectionsRefs.current["einstellungen"] = r}/>
        });
    }
    
    if(data !== undefined){
        for(let i = 0; i < data.paragraphs.length; i++){
            let title = data.paragraphs[i].title;
            subsections.push({
                title: title,
                obj: <Paragraph title={title} key={title} ref={(r) => { subsectionsRefs.current[title.toLowerCase()] = r; }}>
                    {strArrayToText(data.paragraphs[i].content)}
                </Paragraph>
            });
        }
    }

    return(
        <div id="datenschutz">
            <Title title="Datenschutz"/>
            
            <div className="content-field">
                <NavTable titles={subsections.map((s) => s.title)} refs={subsectionsRefs.current}/>

                {subsections.map((subsection) => {
                    return subsection.obj;
                })}
                <LoadHandler data={data} data_name="die Datenschutzerklärung"/>
            </div>
        </div>
    );
}


/**
 * Generates the navigation table, where you can scroll onClick to a element on the apge
 * @param {object} props.titles The titles of the elements to scroll to - title has to match the refs-key of the object
 * @param {*} props.refs The references of the elements to scroll to - key has to match the title of the button
 * @returns The element-design of the navigation table as JSX
 */
function NavTable(props){
    return(
        <>
            <SubTitle title="Inhaltsverzeichnis"/>
            <div className="nav-table">
                {props.titles.map((title) => {
                    return (
                        <div className="item" key={title}>
                            <button onClick={() => props.refs[title.toLowerCase()].scrollIntoView({block: "start", behavior: "smooth"})}>
                                {title}
                            </button>
                        </div>
                    );
                })}
            </div>
        </>
    );
}


/**
 * A settings interface to configure the cookie-settings
 */
const SettingsInterface = forwardRef((props, ref) => (
    <div className="scroll-to" ref={ref}>
        <SubTitle title="Einstellungen"/>
        <div className="settings-interface">
            <Setting title="Technische Daten" onToggle={(val) => UpdateConfig("errorLog", val, true)} isOn={CookieAllowed("errorLog")}/>
            <SplitLine count={1} width="thin"/>
            <Setting title="Google Maps" onToggle={(val) => UpdateConfig("googleMaps", val, true)} isOn={CookieAllowed("googleMaps")}/>
        </div>
    </div>
));

/**
 * A single setting in the cookie-configs
 * @param {string} props.title The title of the setting
 * @param {function} props.onToggle will be called when the toggle is pressed
 * @param {boolean} props.isOn start-configuration of the toggle
 * @returns The setting-element as JSX
 */
function Setting(props){
    return (
        <div className="setting">
            <p className="title">{props.title}</p>
            <Toggle onToggle={props.onToggle} isToggled={props.isOn}/>
        </div>
    );
}


/**
 * A Paragraph in the Datenschutz-Webpage
 * @param {string} props.title The title of the paragraph
 * @param {JSX} props.children The content of the paragrap
 * @returns The paragraph-element as JSX
 */
const Paragraph = forwardRef((props, ref) => (
    <div className="paragraph scroll-to" ref={ref}>
        <SubTitle title={props.title}/>
        <p className="text">{props.children}</p>
    </div>
));


export function ScrollToParagraph(p){
    subsectionsRefs.current[p].scrollIntoView({block: "start", behavior: "smooth"});
}
