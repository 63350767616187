import $ from 'jquery';
import { useState, useEffect } from 'react';
import { ScrollToParagraph } from '../Pages/Datenschutz';

/**
 * Loads the JSON file at the path. First it returns null. When the data is loaded from the JSON file it triggers a setState refresh and returns the data
 * @param {string} path the path to the JSON file with /public/ as root
 * @returns The data of the JSON file once it's loaded as a JS-Object
 */
export function useGetJSON(path){
    const [data, setData] = useState();

    useEffect(() => {
        LoadJSON(process.env.PUBLIC_URL + "/" + path).then(function(content) { setData(content); });
    }, [path]);

    return data;
}

/**
 * Calls the jquerry function to get the JSON data
 * @param {string} path the path to the JSON file 
 * @returns The data of the JSON file
 */
function LoadJSON(path){
    return $.getJSON(path, function(result) {
        return result;
    });
}


/**
 * Generates an array with strings and HTML-objects to translate \n--line-breaks into HTML-language
 * @param {string} text the string to be turned into HTML-code
 * @returns 
 */
export function textToHtml(text){
    let content = [""];
    let chars = [...text];
    
    for(let i = 0; i < chars.length; i++){
        if(chars[i] === '\n') {
            content.push(<br key={i}/>);
            content.push("");
        }
        else content[content.length - 1] += chars[i];
    }

    return content;
}
/**
 * Converts a string to a link to another page
 * @param {*} line the string to be turned into a link. Needs a very specific structure: "<link>{url to link to}<txt>{text of the link}"
 * @returns an <a>-link with the url as href and the text as content-text
 */
function lineToLink(line){
    line = line.slice(6);
    let [url, text] = line.split("<txt>");
    return <a href={url} target="_blank" rel="noreferrer" key={url}>{text}</a>
}

function lineToScrollLink(line){
    line = line.slice(12);
    let [p, text] = line.split("<txt>");
    return <button className="dat-scroll-button" onClick={() => ScrollToParagraph(p)} key={p}>{text}</button>
}

/**
 * Converts a string-array to HTML-objects using @see textToHTML also converting links via @see lineToLink
 * @param {object} strArray The string-array containing the data to convert
 * @returns An object containing the JSX-elements made up from the string-array
 */
export function strArrayToText(strArray){
    if(strArray === undefined) return <></>;
    
    return strArray.map((line) => {
        if(line.slice(0, 6) === "<link>") return lineToLink(line);
        else if(line.slice(0, 12)=== "<dat_scroll>") return lineToScrollLink(line);
        return textToHtml(line);
    })
}

/**
 * Converts all umlauts from a string to unicode-characters (e.g. ä -> ae)
 * @param {*} str the string to convert the umlauts from
 * @returns the string without umlauts
 */
export function strRemoveUmlauts(str){
    str = str.replace(/\u00c4/g, "Ae");
    str = str.replace(/\u00e4/g, "ae");
    str = str.replace(/\u00d6/g, "Oe");
    str = str.replace(/\u00f6/g, "oe");
    str = str.replace(/\u00dc/g, "Ue");
    str = str.replace(/\u00fc/g, "ue");
    str = str.replace(/\u00df/g, "ss");
    return str;
}


/**
 * Loads the latest videos feed from the FBG-Bremen YouTube-Channel and converts it to json
 * @returns The JSON-object containing the data of the latest videos from the feed
 */
export function useGetLatestVideos(){
    const [videos, setVideos] = useState([]);
    var channelId = "UChUaD69cuuTJSUuu_fHJgFQ";

    const baseUrl = 'https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fwww.youtube.com%2Ffeeds%2Fvideos.xml%3Fchannel_id%3D';

    useEffect(() => {
        (async () => {
            if (channelId) {
                try {
                    const data = await fetch(`${baseUrl}${channelId}`).then(response => response.json());
                    setVideos(data.items);
                } catch (error) {
                    console.log(error);
                }
            }
        })();
    }, [channelId]);

    return videos;
}