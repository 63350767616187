import { Link } from "react-router-dom";

import '../../CSS/Pages/Homepage.css';

import { Title, SubTitle, LoadHandler } from "../Components/Components.js";
import { strArrayToText } from "../NonJSX/DataHandler.js";
import { useGetJSON } from "../NonJSX/DataHandler.js";


/**
 * Generates the body of the Homepage-Webpage
 * @returns The page-design as JSX
 */
export default function Homepage(){
    document.title = "FBG Bremen";

    return (
        <div id="homepage">
            <Title title="FBG-Bremen"/>

            <div className="content-field">
                <TopVerse/>

                <GottesdienstePreview/>

                <News/>

                <FaithIntroduction/>
            </div>
        </div>
    );
}

/**
 * Generates the verse-banner placed at the top of the homepage
 * @returns The verse-banner as JSX
 */
function TopVerse(){
    const data = useGetJSON("Data/homepage.json");

    return (
        <LoadHandler data={data} data_name="der Vers">
            {
                data !== undefined &&
                <div className="top_verse">
                    <p className="text">{data.top_verse.text}</p>
                    <p className="passage">{data.top_verse.passage}</p>
                </div>
            }
        </LoadHandler>
    )
}


/**
 * Generates an element showing upcoming news, if there are any. Otherwise it returns nothing
 * @returns an element showing the upcoming news as JSX if there are any
 */
function News(){
    const data = useGetJSON("Data/veranstaltungen.json");

    if(data === undefined || data.news.length === 0) return;

    return (
        <>
            <SubTitle title="Veranstaltungen" className="newsTitle"/>
            {data.news.map((event) => <div className="event" key={event.title}>
                <div className="event-header">
                    <h3 className="title">{event.title}</h3>
                    <p className="date">{event.date}{event.endDate !== "" && (" - " + event.endDate)}</p>
                </div>
                <div className="event-content">
                    {event.imgSrc != null && <img className="image" src={event.imgSrc} alt={event.imgSrc}/>}
                    <div className="event-text">
                        <p className="text">
                            {strArrayToText(event.text)}
                        </p>
                        <p className="where">
                            Wo: 
                            <span className="place">
                                {event.place}
                            </span>
                        </p>
                    </div>
                </div>
            </div>)}
        </>
    );
}


/**
 * Generates an element with our gottesdienste-timetable and an invitation to come
 * @returns The object as JSX
 */
function GottesdienstePreview(){
    const data = useGetJSON("Data/gottesdienste.json");

    return (
        <>
            <SubTitle title="Unsere Gottesdienste"/>
            <div className="gottesdienste-preview">
                <LoadHandler data={data} data_name="der Zeitplan">
                    {
                        data !== undefined &&
                        <div className="zeitplan homepage">
                            <table>
                                <tbody>
                                    {data.german.timetable.table.map((termin) => {
                                        return(
                                            <tr key={termin.tag + termin.uhrzeit}>
                                                <td>{termin.tag}</td>
                                                <td>{termin.uhrzeit}</td>
                                                <td>{termin.typ}</td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    }
                </LoadHandler>
                <div className="invitation">
                    <p className="text">Kommen Sie uns gerne besuchen!</p>
                    <Link to="/gottesdienste" className="btn-link">
                        <button>
                            <p>Zur Wegbeschreibung</p>
                        </button>
                    </Link>
                </div>
            </div>
        </>
    );
}

function FaithIntroduction(){
    const data = useGetJSON("Data/homepage.json");

    return (
        <>
            <SubTitle title="Woran wir glauben"/>
            <LoadHandler data={data} data_name="der Inhalt">
                {
                    data !== undefined &&
                    <div className="solas-panel">
                        {
                            data.solas.map((sola) => {
                                if(sola === undefined){
                                    return <></>
                                }
                                
                                return(
                                    <Sola content={sola} key={sola.latin}/>
                                );
                            })
                        }
                    </div>
                }
            </LoadHandler>
        </>
    );
}

function Sola(props){
    //<img className="icon front" src={process.env.PUBLIC_URL + props.content.icon} alt={props.content.latin + " - Icon"}/>
    
    return (
        <div className="sola">
            <div className="flip-card">
                <div className="sola-content">
                    <div className="verse">
                        <p className="text">{props.content.verse.text}</p>
                        <p className="passage">{props.content.verse.passage}</p>
                    </div>
                </div>
            </div>

            <div className="topic">
                <p className="title">{props.content.title}</p>
                <p className="subtitle">{props.content.latin}</p>
            </div>
        </div>
    );
}


/**
 * Generates an element with our last YT-video and a doctrine-verse-banner
 * @returns The object as JSX
 */
/*
function PredigtenPreview(){
    let video = useGetLatestVideos()[0];
    if (video === undefined) return;

    return (
        <>
            <SubTitle title="Lehre"/>
            <div className="predigten-preview">
                <div className="video">
                    <Video url={video.link} useYt={CookieAllowed("youtube")}/>
                    <p>{video.title}</p>
                </div>

                <div className="lehre">
                    <div className="vers">
                        <p>„Wir aber predigen den gekreuzigten Christus...”</p>
                        <p>1. Korinther 1, 23a</p>
                    </div>

                    <Link to="/aktuelle-predigten" className="btn-link">
                        <button>
                            <p>Weitere Predigten</p>
                        </button>
                    </Link>
                </div>
            </div>
        </>
    );
}
*/
