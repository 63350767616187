import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import App from './JS/App';
import { CookieAllowed } from './JS/NonJSX/CookieConfig';
import { SendToServer } from './JS/NonJSX/ServerSocket';

//This is the start point of the Website


//Log error messages
var errorLog = localStorage.getItem("errorLog");
if (errorLog === null) errorLog = [];
else errorLog = JSON.parse(errorLog);

while (true) {
    if (errorLog.length === 0) break;
    let data = errorLog[0];
    if (Date.now() - data[7] > 1000 * 60 * 60 * 24) errorLog.shift();
    else break;
}
localStorage.setItem("errorLog", JSON.stringify(errorLog));

window.onerror = function (message, source, lineno, colno, error) {
    console.log("Caught an error... pushing it to storage.");

    // ### UPDATE DATENSCHUTZ-ERKLÄRUNG ON CHANGE DATA 
    //let errorLogEntry = `${event.error}<:>${event.filename}<:>${event.lineno}<:>${event.colno}<:>${event.target}<:>${window}<:>${event.timeStamp}<:>${Date.now()}`;
    let errorLogEntry = {
        message: message,
        source: source,
        line: lineno,
        column: colno,
        realtime: Date.now(),
        sender: "Cookie",
        id: -1
    };

    //Remove error log entries that are too old.
    while (true) {
        if (errorLog.length === 0) break;
        let data = errorLog[0];
        if (Date.now() - data[7] > 1000 * 60 * 60 * 24) errorLog.shift();
        else break;
    }

    errorLog.push(errorLogEntry);

    if (CookieAllowed("errorLog")) {
        SendToServer("error", errorLogEntry);
    }

    localStorage.setItem("errorLog", JSON.stringify(errorLog));
};

export function getErrorLog() {
    let logJSON = localStorage.getItem("errorLog");
    if (logJSON === null) {
        return [];
    }

    let log = JSON.parse(logJSON);
    for(let i = 0; i < log.length; i++){
        if(log[i].realtime < Date.now() - 86400000){
            log.splice(i, 1);
            i--;
        }
    }
    localStorage.setItem("errorLog", JSON.stringify(log));

    return log;
}


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
        <App />
    </BrowserRouter>
);
