import React, { useState, useEffect, useLayoutEffect } from 'react';
import { Link, useLocation } from "react-router-dom";


/**
 * Generates the navbar of the website
 * @returns the navbar as JSX
 */
export default function Navbar(){
    InitializeNarrowScreenWatcher();

    const handleScroll = () => {
        let position = window.pageYOffset;
        document.documentElement.style.setProperty('--nav-bg-alpha', Math.max(Math.min(1 - (position - 100) / 500, 1), 0.5));
        let heightPercent = Math.max(Math.min(1 - (position - 200) / 400, 1), 0.7);
        document.documentElement.style.setProperty('--nav-height', `max(min(min(${75 * heightPercent}px, ${7 * heightPercent}vh), ${13 * heightPercent}vw), 40px)`)
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return(
        <nav className="navbar">
            <HomeBtn/>

            <NavContainer>
                <NavItem type="Button" title="Startseite" link="/"/>

                <NavItem type="Dropdown" title="Über uns">
                    <NavItem type="DChild" title="Gottesdienste" link="/gottesdienste"/>
                    <NavItem type="DChild" title="Glaubensbekenntnis" link="/glaubensbekenntnis"/>
                </NavItem>

                <NavItem type="Button" title="Kontakt" link="/kontakt"/>

                <NavItem type="Button" title="Русский" link="/russian"/>
            </NavContainer>
        </nav>
    );
}

/*
export function InternNavbar(){
    const [rights, setRights] = useState({admin: false});
    
    useEffect(() => {
        RequestFromServer("get_rights", setRights, localStorage.getItem("intern_token"));
    }, []);

    return(
        <nav className="navbar">
            <HomeBtn/>
            
            <h4 className="intern-title">INTERN</h4>
    
            <NavContainer>
                <NavItem type="Button" title="Startseite" link="/intern"/>

                {
                    rights.admin &&
                    <NavItem type="Dropdown" title="Admin">
                        <NavItem type="DChild" title="Metrics" link="/intern/metrics"/>
                        <NavItem type="DChild" title="Error Log" link="/intern/error_log"/>
                    </NavItem>
                }
            </NavContainer>
        </nav>
    );
}
*/


var isNarrowScreen;
var setIsNarrowScreen;

/**
 * Finds out whether the screen is less than 475px wide and calls a setState refresh when it changes
 * @returns whether the screen is less than 475px wide
 */
function InitializeNarrowScreenWatcher(){
    // Listener to change type of Navbar depending on the screen size (screen-width smaller than 475px -> Dropdown-Navbar; else -> Casual Line-Navbar).
    // Important, because screen width can dynamically change, when e.g. rotating the screen. Otherwise Layout might break

    const mediaWatcher = window.matchMedia("(max-width: 550px)");

    //Set state with initial value
    [isNarrowScreen, setIsNarrowScreen] = useState(mediaWatcher.matches);
    
    //Listener for when the screen width changes
    function updateIsNarrowScreen(e) {
        setIsNarrowScreen(e.matches);
    }
    mediaWatcher.addEventListener('change', updateIsNarrowScreen);
}



function HomeBtn(){
    return(
        <Link to="/" className="home-btn">
            <button className="btn-h">
                <img className="image" src={process.env.PUBLIC_URL + "/Icons/fbg-bremen_icon-black-fg.png"} alt="FBG-Bremen Icon"/>
            </button>
        </Link>
    );
}



/**
 * Generates the container of the Nav-Buttons (except home-icon) depending on whether it is a narrow screen or not
 * @param {*} props.children the content of the Nav-Container | Only NavItems with a specified type are allowed
 * @returns The navbar interface as JSX
 */
function NavContainer(props){
    //currently any other objects than those declared in MapToObj (Nav_"name") will be ignored
    let content = <NavContent> {React.Children.map(props.children, (child) => { return child; })} </NavContent>;

    if(isNarrowScreen) return <Dropdown>{ content }</Dropdown>
    else return <div className="line-nav">{ content }</div>
}

/**
 * Calls MapToObj on the NavItems
 * @param {*} props.children the elements of the interface
 * @returns the objects mapped to their corresponding JSX-element
 */
function NavContent(props){
    const content = React.Children.toArray(props.children);
    return MapToObj(content);
}

/**
 * //returns for every NavItem the equivalent object selected by the specified type | also dependant on type of navbar (dropdown/line-nav))
 * @param {*} objs The NavItems to map to the corresponding JSX-element
 * @returns an object containing all JSX-elements
 */
function MapToObj(objs){
    return objs.map((obj) => {
        if(obj === undefined || obj.props === undefined) return null;
        
        switch(obj.props.type){
            case "Button":
                if(isNarrowScreen) return <DropdownItem title={obj.props.title} link={obj.props.link}  key={obj.props.title}/>;
                else return <NavbarBtn title={obj.props.title} link={obj.props.link}  key={obj.props.title}/>;
            case "Dropdown":
                if(isNarrowScreen) return <NestedDropdown title={obj.props.title}  key={obj.props.title}>{ MapToObj(React.Children.toArray(indentNavItemChildren(obj.props.children))) }</NestedDropdown>;
                else return <Dropdown title={obj.props.title} key={obj.props.title}>{ MapToObj(React.Children.toArray(obj.props.children)) }</Dropdown>;
            case "DChild":
                if(isNarrowScreen) return <DropdownItem title={obj.props.title} link={obj.props.link}  key={obj.props.title}/>;
                else return <DropdownItem title={obj.props.title} link={obj.props.link} key={obj.props.title}/>;
            case "NestedDropdown":
                return <NestedDropdown title={obj.props.title}  key={obj.props.title}>{ MapToObj(React.Children.toArray(indentNavItemChildren(obj.props.children))) }</NestedDropdown>;
            default:
                console.warn("Object not identified! Object of type: " + obj.type.name);
                return null;
        }
    });
}

//#region Navbar-Objects

/**
 * 
 * @param {string} props.link The link to the subsite of this website | probably: url = fbg-bremen.de + {link}
 * @param {string} props.title The title of the button
 * @returns The button as JSX
 */
function NavbarBtn(props){
    const location = useLocation().pathname;
    return(
        <Link to={props.link} className="btn-nav">
            <button className="btn-n btn-clickable">
                <p className={`text ${location === props.link ? "page-loaded" : ""}`}>{props.title}</p>
            </button>
        </Link>
    );
}


var closeDropdown;
/**
 * Handles dropdown behaviour and generates the button to open and close the dropdown-menu
 * @param {string} props.title The title of the dropdown button
 * @param {*} props.children The content of the dropdown-menu. Will be passed on to the DropdownMenu-element
 * @returns The dropdown button and the dropdown-menu (if open) as JSX
 */
function Dropdown(props){
    //Dropdown opening behaviour - opens also when hovering
    const [isOverButton, setIsOverButton] = useState(false);
    const [isOverList, setIsOverList] = useState(false);
    const [isOpen, setIsOpen] = useState();
    const [animation, setAnimation] = useState(0);
    closeDropdown = () => { setIsOpen(false); setIsOverList(false); setIsOverButton(false); }

    //set if open depending on the values of the four variables
    useLayoutEffect(() => {
        if (isOpen && !isOverButton && !isOverList) {
            setIsOpen(false);
        } else if (!isOpen && (isOverButton || isOverList)) {
            setIsOpen(true);
            setAnimation(0);
        }
    }, [isOverButton, isOverList, isOpen]);


    //determine whether an item in the dropdown leads to the selected page to make the font bold if it does.
    const location = useLocation().pathname;
    let innerPageLoaded = false;
    for (let i = 0; i < props.children.length; i++) {
        const element = props.children[i];
        if(element.props.link === location) innerPageLoaded = true;
    }

    return (
        <div className="dropdown">
            <div className="btn-d" onMouseEnter={() => { setIsOverButton(true); }} onMouseLeave={() => { setIsOverButton(false); } } onClick={() => { setIsOpen(!isOpen); setIsOverButton(!isOpen); setIsOverList(false); }}>
                <DropdownTopBtn title={props.title} isLoaded={innerPageLoaded}/>
            </div>

            {
                isOpen &&
                <div onMouseEnter={() => { setIsOverList(true); }} onMouseLeave={() => { setIsOverList(false); } }>
                    <DropdownMenu animIdx={animation}>
                        {props.children}
                    </DropdownMenu>
                </div>
            }
        </div>
    );
}


//#endregion


//#region Dropdown-Utility  

/**
 * Generates the button to open a dropdown menu depending of the screen-width (title/dropdown-btn-icon)
 * @param {boolean} props.isLoaded whether a page of the dropdown-menu-buttons is currently visited
 * @returns The button as JSX
 */
function DropdownTopBtn(props){
    if(isNarrowScreen) return (
        <button className="icon-d">
            <img className="img" src={process.env.PUBLIC_URL + "/Icons/DropdownIcon.png"} alt="Dropdown Icon"/>
        </button>
    );

    return (
        <>
            <button className={`text-d nav-ctr-clr ${props.isLoaded ? "page-loaded" : ""}`}>{props.title}</button>
            <img className="dropdown-down-arrow" src={process.env.PUBLIC_URL + "/Icons/ArrowDownIcon.png"} alt="Arrow down Icon"/>
        </>
    );
}

/**
 * Generates the dropdown menu
 * @param {int} props.animIdx An index to specify the state-change-animation | -1: close | 0: none | 1: open
 * @param {*} props.children The content of the dropdown menu
 * @returns The menu as JSX
 */
function DropdownMenu(props){
    return(
        <div className="menu-d" animation={props.animIdx}>
            {props.children}
        </div>
    );
}


/**
 * Creates a dropdown that is contained in another dropdown
 * @param {string} props.title The title of the dropdown
 * @param {*} props.children The content of the dropdown-manu
 * @returns The dropdown-button as JSX
 */
function NestedDropdown(props){
    const [isOpen, setIsOpen] = useState();

    return (
        <>
            <div className="dropdown-nested" onClick={() => { setIsOpen(!isOpen); }}>
                <button className="item">{props.title}</button>
                <img className="dropdown-down-arrow" src={process.env.PUBLIC_URL + "/Icons/ArrowDownIcon.png"} alt="Arrow down Icon"/>
            </div>

            {
                isOpen && props.children
            }
        </>
    );
}

/**
 * Creates a button that is used inside a dropdown-menu
 * @param {string} props.link The link to the subsite of this website | probably: url = fbg-bremen.de + {link}
 * @param {string} props.title The title of the dropdown-button
 * @returns The dropdown-button as JSX
 */
function DropdownItem(props){
    const location = useLocation().pathname;
    return(
        <Link to={props.link} className="item-dm">
            <button className={`btn-dmi btn-clickable ${location === props.link ? "page-loaded" : ""}`} onClick={() => { if(closeDropdown != null) {closeDropdown();} }}>{props.title}</button>
        </Link>
    );
}


/**
 * Changes the string-title of a NavItem to indent the item to the right. Used for nested dropdowns
 * @param {*} children The children elements to indent
 * @returns The children elements but indented
 */
function indentNavItemChildren(children){
    if(!Array.isArray(children)) return children;

    return children.map((child) => {
        return <NavItem type={child.props.type} title={"   " + child.props.title} link={child.props.link} key={child.props.title}>
            {child.props.children !== undefined ? indentNavItemChildren(child.props.children) : child.props.children}
        </NavItem>;
    });
}


//#endregion


/**
 * An empty element to parse props and show that it is an element belonging to the navbar
 * Optional props: title: title-text | link: route to webpage
 */
function NavItem(props){
}
