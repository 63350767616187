

/**
 * Generates one or more horizontal green line
 * @param {int} props.count the amount of lines side by side
 * @param {string} props.length a tag to select the length of the lines
 * @param {string} props.width a tag to select the width of the lines
 * @returns A div containing the line-elements as JSX
 */
export function SplitLine(props){
    let count = 1
    if(props.count !== undefined) count = props.count;

    let lines = [];
    for (let i = 0; i < count; i++){
        lines.push(<div className="line" key={i} length={props.length} width={props.width}/>);
    }

    return (
        <div className="split-lines">{lines}</div>
    );
}