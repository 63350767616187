import React, { useEffect, useState } from 'react';

/**
 * Generates a very large text to be used as a title
 * @param {string} props.title The text of the title 
 * @returns The object as JSX
 */
export function Title(props){
    return(
        <div className="title-component">
            <div className="topLine"/>
            <p className="title">{props.title}</p>
        </div>
    );
}

/**
 * Generates a large text to be used as a subtitle
 * @param {string} props.title The text of the subtitle 
 * @returns The object as JSX
 */
export function SubTitle(props){
    return(
        <div color={props.color} underline={props.underline} className={"subtitle-component colorizable " + props.className}>
            <p>{props.title}</p>
        </div>
    );
}


/**
 * Generates a toggle-object 
 * @param {boolean} props.isToggled The start setting of the toggle | true: On | false: Off
 * @param {function} props.onToggle The function to call when the toggle is pressed | Takes a boolean with the new value
 * @returns The object as JSX
 */
export function Toggle(props){
    let isToggled = false;
    if(props.isToggled !== undefined) isToggled = props.isToggled;

    let [checked, setChecked] = useState(isToggled);
    let onClick = () => {
        props.onToggle(!checked);
        setChecked(!checked);
    }

    return(
        <div className="toggle" onClick={onClick} ischecked={checked ? "true" : "false"}>
            <span className="slider" ischecked={checked ? "true" : "false"}/>
        </div>
    );
}


/**
 * Generates an iframe that shows a YouTube when YouTube-cookies are allowed. Otherwise it shows an img with the thumbnail of the video
 * @param {string} props.url The url of the YouTube-video 
 * @returns 
 */
export function Video (props){
    //If YouTube weren't allowed only show thumbnail
    /*let videoFrame = (
        <a className="video-frame" noyt="true" href={URLtoYTLink(props.url)} target="_blank" rel="noreferrer">
            <img src={URLtoYTThumbnail(props.url)} alt={"YouTube-video _thumbnail" + props.url + " _FBG-Bremen"}/>
        </a>
    );*/

    return <iframe className="video-frame" src={URLtoEmbed(props.url)} title={props.url} allowFullScreen={true}/>;
}


/**
 * Calculates the YouTube-Embed-Link for the set YT-id
 * @param {*} id the id of the YouTube-video
 * @returns the embed-url of the video
 */
function URLtoEmbed(id){
    var startIndex = id.search("v=");
    if(startIndex === -1) return "https://www.youtube-nocookie.com/embed/" + id;

    return "https://www.youtube-nocookie.com/embed/" + id.substring(startIndex + 2);
}


/**
 * Returns the children if data is not undefined, or the DataLoading-Screen if it is
 * @param {*} props.data the data to check whether it is defined
 * @param {*} props.children the children to return when Data is defined
 * @returns 
 */
export function LoadHandler(props){
    if(props.data === undefined) return <DataLoading data_name={props.data_name}/>
    else {
        clearTimeout(objectTimeout);
        return props.children;
    }
}


var showLoadingIndicator = false;
var objectTimeout;
/**
 * Creates the loading-screen after 3 seconds of timeout. Also animates the Loading... text
 * @returns the loading-screen as JSX
 */
export function DataLoading(props){
    let [loadingText, SetLoadingText] = useState("Loading");

    useEffect(() => {
        var interval;

        objectTimeout = setTimeout(() => {
            showLoadingIndicator = true;
            interval = setInterval(() => {
                SetLoadingText(prevText => prevText + ".");
            }, 1000);
        }, 3000);
        
        return () => {
            clearInterval(interval);
            showLoadingIndicator = false;
        }
    }, []);

    if(!showLoadingIndicator) return;

    if(loadingText.length > 10) SetLoadingText("Loading");

    return (
        <div className="data-loading">
            <p className="loading">{loadingText}</p>
            <p className="load-error">Wenn nach langer Zeit {props.data_name === undefined ? "die Daten" : props.data_name} nicht geladen werden, prüfen sie bitte, ob sie eine ausreichende Internetverbindung haben.</p>
            <p className="load-error">Wenn es dennoch nicht funktionieren sollte, können sie gerne einen Fehler über das Kontaktformular melden. Gerne können sie auch zulassen, dass Browser-Daten mitversendet werden, damit das Problem schnellstmöglich gelöst werden kann.</p>
            <p className="thanks">Vielen Dank</p>
        </div>
    );
}



export function NotFoundPage(){
    return(
        <>
            <h2>404 - Nicht gefunden!</h2>
            <br/>
            <p>Die eingegebene URL ist leider nicht verfügbar. Es gibt keine Seite, die zu dieser URL gehört.</p>
            <br/>
            <p>Wenn sie hierher über einen Knopf der Website gelangt sind, bitte ich sie diesen Fehler über das Kontaktformular zu melden und den verwendeten Knopf anzugeben.<br/>Vielen Dank</p>
        </>
    );
}



//get scroll position
/*const [scrollPosition, setScrollPosition] = useState(0);
    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
        console.log(position);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);*/