import '../../CSS/Pages/Glaubensbekenntnis.css';

import { Title, LoadHandler } from "../Components/Components.js";
import { textToHtml } from "../NonJSX/DataHandler.js";
import { useGetJSON } from "../NonJSX/DataHandler.js";


/**
 * Generates the body of the Glaubensbekenntnis-Webpage
 * @returns The page-design as JSX
 */
export default function Homepage(){
    document.title = "Glaubensbekenntnis - FBG Bremen";

    const glaubensbekenntnis = useGetJSON("Data/glaubensbekenntnis.json");

    return (
        <div id="glaubensbekenntnis">
            <Title title="Glaubensbekenntnis"/>

            <div className="content-field">
                <LoadHandler data={glaubensbekenntnis} data_name="das Glaubensbekenntnis">
                    {
                        glaubensbekenntnis !== undefined &&
                        glaubensbekenntnis.categories.map((cat, index) => { 
                            let edge = 0;
                            if(index === 0) edge = -1;
                            else if(index === glaubensbekenntnis.categories.length - 1) edge = 1;
                            return <Category cat={cat} key={cat.title} edge={edge}/>
                        })
                    }
                </LoadHandler>
            </div>
        </div>
    );
}

/**
 * Generates the element of a single category in the confession of faith
 * @param {*} props.edge Defines wehther top-/bottom-borders are visible | -1: top | 0: none | 1: bottom
 * @returns The category-element as JSX
 */
function Category(props){
    return(
        <div className="category" edge={props.edge}>
            <CatTitle title={props.cat.title}/>
            <CatContent content={props.cat.content}/>
            <CatVerses verses={props.cat.verses}/>
        </div>
    );
}

/**
 * Generates the element for a title in a category
 * @param {string} props.title the title 
 * @returns The title-element as JSX
 */
function CatTitle(props){
    return(
        <h1 className="title">{props.title}</h1>
    );
}
function CatContent(props){
    return (
        <p className="content">{textToHtml(props.content)}</p>
    );
}
function CatVerses(props){
    return (
        <div className="verses-collection">
            {props.verses.map((verse) => { return <p className="verse" key={verse}>{verse + ";"}</p> })}
        </div>
    );
}