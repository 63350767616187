import { useState } from 'react';
import { Toggle } from './Components';
import { SaveCookieConsent, UpdateConfig } from '../NonJSX/CookieConfig';


/**
 * Generates the cookie-banner of the page. Only show one at a time!
 * @param {Object} props.close - the function to call when the CookieBanner should close   
 * @returns The CookieBanner Element as JSX
 */
export default function CookieBanner(props){
    let [doConfigure, setConfiguring] = useState(false);

    return(
        <div className="banner">
            <p className="text">Wir verwenden keine Cookies<br/>Wir nutzen aber Google Maps, welches Cookies verwendet, um ihre Dienste bereitzustellen.
            Wenn Sie es zulassen, werden außerdem Fehlermeldungen ihres Browsers von uns gesammelt, damit wir diese behandeln können.</p>
            <p className="text">Genauere Informationen können Sie auf unserer <a href="/datenschutz">Datenschutz-Seite</a> finden.</p>

            {doConfigure && <ConfigureInterface/>}

            <div className="buttons-shell">
                {!doConfigure && (
                    <>
                        <BannerButton text="Ablehnen" type="reject" onClick={() => { UpdateConfig("all", false, true); props.close(); }}/>
                        <BannerButton text="Einstellen" type="configure" onClick={ () => { UpdateConfig("all", false); setConfiguring(true); } }/>
                        <BannerButton text="Annehmen" type="accept" onClick={() => { UpdateConfig("all", true, true); props.close(); }}/>
                    </>
                )}
                {doConfigure && (
                    <>
                        <BannerButton text="Zurück" type="configure" onClick={() => setConfiguring(false)}/>
                        <BannerButton text="Speichern" type="accept" onClick={() => { SaveCookieConsent(); props.close(); }}/>
                    </>
                )}
            </div>
        </div>
    );
}

function BannerButton(props){
    return(
        <button className="button" type={props.type} onClick={props.onClick}>{props.text}</button>
    );
}


function ConfigureInterface(){
    //<ConfigureSetting title="Alle" onToggle={(val) => UpdateConfig("all", val)}/>
    return(
        <div className="configure-interface">
            <ConfigureSetting title="Fehlermeldungen" onToggle={(val) => UpdateConfig("errorLog", val)}/>
            <ConfigureSetting title="Google Maps" onToggle={(val) => UpdateConfig("googleMaps", val)}/>
        </div>
    );
}

function ConfigureSetting(props){
    return(
        <div className="setting">
            <p className="title">{props.title}</p>
            <Toggle onToggle={props.onToggle}/>
        </div>
    );
}

