import "../CSS/Main.css";

import '../CSS/Components/StylingComponents.css'
import '../CSS/Components/Components.css'
import '../CSS/Components/Titles.css';
import '../CSS/Components/Toggle.css';
import '../CSS/Components/Zeitplan.css';
import '../CSS/Components/CookieBanner.css';

import '../CSS/Components/Footer.css';
import '../CSS/Components/Navbar.css';

import { useState } from "react";

import Navbar from "./Components/Navbar";
import Footer from './Components/Footer';
import Body from './Body';
import CookieBanner from './Components/CookieBanners';
import { LoadCookieConsent } from "./NonJSX/CookieConfig";


/**
 * Puts together Navbar, Body and Fotter to compose the whole page. Also opens cookie-banner if cookie-configs aren't yet set.
 * @returns The complete Webpage as JSX
 */
export default function App() {
  LoadCookieConsent();
  let [cookieBannerIsOpen, closeCookieBanner] = useState(localStorage.getItem("cookie-config") === null);

  return (
    <>
      <Navbar/>
      <Body cookieBannerIsOpen={cookieBannerIsOpen}/>
      <Footer/>
      
      {cookieBannerIsOpen && <CookieBanner close={() => closeCookieBanner(false)}/>}
    </>
  );
}
