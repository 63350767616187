import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { Routes, Route } from 'react-router-dom';

import "../CSS/Body.css";

import Homepage from './Pages/Homepage';

import Gottesdienste from './Pages/Gottesdienste';
import Glaubensbekenntnis from './Pages/Glaubensbekenntnis';

import Kontakt from './Pages/Kontakt';

import Impressum from './Pages/Impressum';
import Datenschutz from './Pages/Datenschutz';

import { NotFoundPage } from './Components/Components';
import { SendToServer } from './NonJSX/ServerSocket';



/**
 * Routes the current url-path to the right page (JS-Function) and collects the metrics.
 * Also loads the playlists data to generate the playlist-pages.
 * @returns The body of the webpage as JSX 
 */
export default function Body(props) {
    //Collect page visit metrics
    let route = useLocation().pathname;
    useEffect(() => {
        SendToServer("visit-count", { path: route });
    }, [route])

    return (
        <div className="body">
            <ScrollToTop>
                <Routes>
                    <Route path='/' element={<Homepage />} />

                    <Route path="/gottesdienste" element={<Gottesdienste lan="ger" />} />
                    <Route path="/russian" element={<Gottesdienste lan="rus" />} />
                    <Route path="/glaubensbekenntnis" element={<Glaubensbekenntnis />} />
                        
                    <Route path="/kontakt" element={<Kontakt />} />

                    <Route path="/impressum" element={<Impressum />} />
                    <Route path="/datenschutz/:scrollTo?" element={<Datenschutz showSettings={!props.cookieBannerIsOpen}/>} />

                    <Route path="*" element={<NotFoundPage />} />
                </Routes>
            </ScrollToTop>
        </div>
    );
}



/**
 * If the location changed, since it last time scrolled to top, it will scroll the page to the top, after it got rendered.
 * @param {*} props.children Will be returned -> nothing will happen to them
 * @returns The children as JSX, that were passed in the props
 */
const ScrollToTop = (props) => {
    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return <>{props.children}</>
};