

var configuration = {
    errorLog: false,
    googleMaps: false
}

/**
 * Checks whether cookies[param] are allowed
 * @param {*} param the key in the cookie-configuration-data
 * @returns whether the cookies from the source defined by [param] are allowed
 */
export function CookieAllowed(param){
    return configuration[param];
}

/**
 * Sets a certain param of the cookie-config to the specified value
 * @param {*} param the key of the param to update | "all" if all parameters should be updated
 * @param {*} value the value that the param/all params will be set to
 * @param {*} save set to true if the cookie-config should automatically be saved after updating it | default: false
 */
export function UpdateConfig(param, value, save = false){
    if(param === "all"){
        configuration = {
            errorLog: value,
            googleMaps: value
        }
    }
    else{
        configuration = {
            ...configuration,
            [param]: value
        };
    }

    if(save) SaveCookieConsent();
}


/**
 * Saves the current cookie-configuration into the localStorage of the browser
 */
export function SaveCookieConsent(){
    localStorage.setItem('cookie-config', JSON.stringify(configuration));
}

/**
 * Loads the cookie-configuration from the localStorage of the browser into memory.
 * If the data doesn't exist in localStorage, a new configuration will be loaded into memory with all values set to false.
 */
export function LoadCookieConsent(){
    configuration = JSON.parse(localStorage.getItem('cookie-config'));
    if(configuration === null) configuration = {
        googleMaps: false,
        errorLog: false
    }
}
