import { Link } from "react-router-dom";


/**
 * Generates the Webpage-Fotter
 * @returns the footer as JSX
 */
export default function Footer(){
    return(
        <footer className="footer">
            <FooterBtn title="Datenschutz" link="/datenschutz"/>
            <FooterBtn title="Impressum" link="/impressum"/>
        </footer>
    );
}



function FooterBtn(params){
    return(
        <Link className="btn-shell" to={params.link}>
            <button className="btn">{params.title}</button>
        </Link>
    );
}

/*
function FooterImgBtn(params){
    return(
        <a className="btn-shell" href={params.href}>
            <img className="img-btn" src={process.env.PUBLIC_URL + "/" + params.imgSrc} alt={params.alt}/>
        </a>
    );
}
*/