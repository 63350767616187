import { Title, SubTitle } from "../Components/Components.js";
import { useGetJSON } from "../NonJSX/DataHandler.js";

import '../../CSS/Pages/Gottesdienste.css';
import { CookieAllowed } from "../NonJSX/CookieConfig.js";


/**
 * Generates the body of the Gottesdienste-Webpage
 * @returns The page-design as JSX
 */
export default function Gottesdienste(props){
    const allLanguagesTxt = useGetJSON("Data/gottesdienste.json");
    if(allLanguagesTxt === undefined) return;

    let isGerman = props.lan === "ger";

    let content = allLanguagesTxt.german;
    if(!isGerman) content = allLanguagesTxt.russian;

    document.title = content.documentTitle;

    return (
        <div id="gottesdienste">
            <Title title={content.title}/>
            
            <div className="content-field">
                <Zeitplan content={content}/>

                <SubTitle title={content.anfahrtTitle}/>
                <Anfahrt content={content}/>
            </div>
        </div>
    );
}

/**
 * Generates the timetable with the gottesdienst-terminen
 * @param {*} props.content the data of the content from gottesdienste.json
 * @returns The timetable-element as JSX
 */
function Zeitplan(props){
    return(
        <>
            <SubTitle title={props.content.timetable.title}/>
            <div className="zeitplan godi-page">
                <table>
                    <tbody>
                        {props.content.timetable.table.map((termin) => {
                            return(
                                <tr key={termin.tag + termin.uhrzeit}>
                                    <td>{termin.tag}</td>
                                    <td>{termin.uhrzeit}</td>
                                    <td>{termin.typ}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </>
    );
}


function Anfahrt(props){
    return(
        <div className="anfahrt">
            <div className="address-map-shell">
                <Map content={props.content}/>
                <div className="address">
                    <h3 className="paragraph">{props.content.adresse.title}</h3>
                    <p className="paragraph">{props.content.adresse.strasse}</p>
                    <p className="paragraph">{props.content.adresse.ort}</p>
                </div>
            </div>
            <Wegbeschreibung content={props.content}/>
        </div>
    );
}

function Map(props){
    if(!CookieAllowed("googleMaps")){
        return (
            <>
                <div className="maps image-map-area">
                    <img className="map" src="Images/map.png" alt="FBG-Bremen Gebetshaus Standort - Screenshot von OpenStreetMap"/>
                    <a href="https://goo.gl/maps/WUPk4Cy7PLswe8Es7" target="_blank" rel="noreferrer">
                        <p className="btn">{props.content.mapsBtn}</p>
                    </a>
                </div>
            </>
        );
    }

    return (
        <div className="maps">
            <iframe className="map" title="FBG-Bremen Anfahrt _ Maps" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2399.286945595238!2d8.955790300000002!3d53.0331801!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47b0df4066c90c79%3A0x6825acbfc5524e8d!2sUphuser%20Heerstra%C3%9Fe%20102%2C%2028832%20Achim!5e0!3m2!1sde!2sde!4v1676471617833!5m2!1sde!2sde" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"/>
        </div>
    );
}

function Wegbeschreibung(props){
    return(
        <div className="wegbeschreibung">
            <h3 className="paragraph">{props.content.wegbeschreibung.title}</h3>
            {props.content.wegbeschreibung.absaetze.map((str) => {
                return <p className="paragraph" key={str}>{str}</p>
            })}
            <img className="image" src="Images/Gemeindehaus.jpg" alt="FBG-Bremen_building_view-from-street"/>
        </div>
    )
}