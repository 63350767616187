import { useState } from "react";
import emailjs from "emailjs-com";

import '../../CSS/Pages/Kontakt.css';

import { Title } from "../Components/Components.js";
import { getErrorLog } from "../../index.js";
import { SendToServer } from "../NonJSX/ServerSocket.js";
import { MIN_FORM_FILLOUT_TIME } from "../Constants.js";



var sendSuccessful = false;
var setSendSuccessful = null;


var data = {
    addressee: "unbestimmt",
    name: "",
    reply_to: "",
    subject: "",
    message: "",
    send_browser_data: false,
    entry_start_time: Date.now() + 864000000
}
var setData = null;

function resetData(){
    setData({
        addressee: "unbestimmt",
        name: "",
        reply_to: "",
        subject: "",
        message: "",
        send_browser_data: false,
        entry_start_time: Date.now() + 864000000
    });
}



/**
 * Generates the body of the Kontakt-Webpage
 * @returns The page-design as JSX
 */
export default function Kontakt(){
    document.title = "Kontakt - FBG Bremen";

    [sendSuccessful, setSendSuccessful] = useState(false);

    return (
        <div id="kontakt">
            <Title title="Kontakt"/>
            
            <div className="content-field">
                <Form/>

                {
                    sendSuccessful &&
                    <p className="send-successful">Ihre Nachricht wurde erfolgreich gesendet. Wir werden uns schnellstmöglich darum kümmern.</p>
                }

                <p className="info-text">Die mit * gekennzeichneten Felder müssen wahrheitsgemäß ausgefüllt werden. Der Name ist hiervon ausgenommen, damit Sie anonym bleiben können, wenn Sie es wünschen.</p>
                
                <p className="info-text">Spam und Werbung sind ausdrücklich verboten.</p>
            </div>
        </div>
    );
}


/*function ClearForm(){
    //localStorage.setItem('kontakt-data', JSON.stringify({ data }));

    return (
        <div className="clear-form">
            <button onClick={() => {
                setData({
                    addressee: "unbestimmt",
                    name: "",
                    reply_to: "",
                    subject: "",
                    message: ""
                });
            }}>Leeren</button>
        </div>
    );
}*/


/**
 * Generates the form and stores its data
 * @returns The form as JSX
 */
function Form(){
    [data, setData] = useState(
        {
            addressee: "unbestimmt",
            name: "",
            reply_to: "",
            subject: "",
            message: "",
            send_browser_data: false,
            entry_start_time: Date.now() + 864000000
        }
    );

    return(
        <form className="form" onSubmit={SendEmail}>
            
            <Addressee/>
            <Name/>
            <Sender/>
            <Subject/>
            <Message/>

            { data.addressee === "fehlermeldung" && <SendBrowserData/> }

            <p className="datenschutz">Indem Sie auf "Nachricht senden" klicken, erklären Sie sich mit unseren Datenschutzbestimmungen einverstanden.<br/>
            Für nähere Informationen siehe: <a href="/datenschutz/kontaktformular">Datenschutz</a></p>            
            <input type="submit" className="submit" value="Nachricht senden"/>
        </form>
    );
}

/**
 * Generates the dropdown-menu to select the addressee
 * @returns The object as JSX
 */
function Addressee(){
    return(
        <div className="input">
            <label htmlFor="addressat-dropdown">Adressat:</label>

            <select id="addressat-dropdown" className="addressee" name="addressee" value={data.addressee} onChange={HandleInputChange}>
                <option value="unbestimmt">Unbestimmt</option>
                <option value="vorstand">Vorstand</option>
                <option value="seelsorge">Seelsorge</option>
                <option value="administrator">Administrator</option>
                <option value="fehlermeldung">Technikproblem melden</option>
            </select>
        </div>
    );
}

/**
 * Generates the text-input-field to enter the name of the sender
 * @returns The object as JSX
 */
function Name(){
    return(
        <div className="input">
            <label htmlFor="name-inp">{`Name: ${data.addressee !== "fehlermeldung" ? "*" : ""}`}</label>

            <input
                type="text"
                id="name-inp"
                className="name"
                required={data.addressee !== "fehlermeldung"}
                placeholder=""
                name="name"
                value={data.name}
                onChange={HandleInputChange}
            ></input>
        </div>
    );
}

/**
 * Generates the email-input-field to enter the email-address of the sender
 * @returns The object as JSX
 */
function Sender(){
    return(
        <div className="input">
            <label htmlFor="sender-inp">{`E-Mail: ${data.addressee !== "fehlermeldung" ? "*" : ""}`}</label>

            <input
                type="email"
                id="sender-inp"
                className="sender"
                required={data.addressee !== "fehlermeldung"}
                placeholder=""
                name="reply_to"
                value={data.reply_to}
                onChange={HandleInputChange}
            ></input>
        </div>
    );
}

/**
 * Generates the email-input-field to enter the subject of the message
 * @returns The object as JSX
 */
function Subject(){
    return(
        <div className="input">
            <label htmlFor="subject-inp">{`Betreff: ${!["seelsorge", "fehlermeldung"].includes(data.addressee) ? "*" : ""}`}</label>

            <input
                type="text"
                id="subject-inp"
                className="subject"
                required={!["seelsorge", "fehlermeldung"].includes(data.addressee)}
                placeholder=""
                name="subject"
                value={data.subject}
                onChange={HandleInputChange}
            ></input>
        </div>
    );
}

/**
 * Generates the email-input-field to enter the message
 * @returns The object as JSX
 */
function Message(){
    return(
        <div className="input">
            <label htmlFor="message-inp">Nachricht: *</label>

            <textarea
                type="text"
                id="message-inp"
                className="message"
                required
                placeholder=""
                name="message"
                value={data.message}
                onChange={HandleInputChange}
            ></textarea>
        </div>
    );
}

/**
 * Generates the checkbox to select whether browser-data is allowed to be send
 * @returns The object as JSX
 */
function SendBrowserData(){
    return(
        <div className="input">
            <label htmlFor="sendBrowserData-inp" className="send-browser-data-label">Erlauben Browser-Fehlermeldungen zu senden</label>

            <input
                type="checkbox"
                id="sendBrowserData-inp"
                className="send-browser-data"
                name="send_browser_data"
                onChange={e => HandleInputChange(e)}
            ></input>
        </div>
    );
}


/**
 * Gets called by an input-field when its data changes to handle the changed data
 * @param {*} event the input-event
 */
function HandleInputChange(event){
    const target = event.target;
    const name = target.name;
    const value = target.name !== "send_browser_data" ? target.value : target.checked;
    if(data.entry_start_time === undefined || data.entry_start_time > Date.now()){
        data.entry_start_time = Date.now();
        console.log("Setting start time: " + data.entry_start_time);
    }

    setData({
        ...data,
        [name]: value
    });

    /*localStorage.setItem('kontakt-data', JSON.stringify({
        ...data,
        [name]: value
    }));*/
}


/**
 * Uses the data of the form to send an email via EmailJS
 * @param {*} event The form-event
 */
async function SendEmail(event) {
    event.preventDefault();
    if(Date.now() - data.entry_start_time < MIN_FORM_FILLOUT_TIME){
        window.alert("Um Spam zu verhindern, darf das Formular nicht zu schnell abgesendet werden. Bitte warten Sie wenige Sekunden.");
        return;
    }

    let mail_template = 'template_kphc4s3';
    if(data.addressee === "fehlermeldung"){
        if(data.send_browser_data){
            let id = Math.floor(Math.random() * Math.pow(2, 16)).toString(16);
            data.id = id;

            SendErrorLogToServer(id);
        }
        else data.id = "---";

        mail_template = 'template_0f2igit';
    }

    let mail = {
        ...data,
        entry_start_time: undefined
    }

    emailjs.send('service_ycfm4qb', mail_template, mail, 'rQg4LpZdzrIPTr3nz').then((result) => {
        console.log(result.text);
    });
    let successful = await SendToServer("email", mail);

    if(!successful){
        window.alert("Beim Absenden ist ein Fehler aufgetreten.\nBitte versuchen Sie es etwas später erneut.");
    }
    else{
        resetData();
        setSendSuccessful(true);
    }
}

function SendErrorLogToServer(id){
    let errorLog = getErrorLog();
    console.log("Sending Error-Log to server...:");
    console.dir(errorLog);
    for(let i = 0; i < errorLog.length; i++){
        errorLog[i].sender = id;
    }
    SendToServer("error-log", errorLog);
}