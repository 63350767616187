import axios from "axios";

const server_url = "https://fbg-bremen.de:5000/"; //"http://102.168.178.157:5000/";

export async function SendToServer(target, data, response = undefined, auth = undefined) {
    var successful = await axios({
        method: "post",
        url: server_url + target,
        headers: {
            Authorization: 'Bearer ' + auth
        },
        data: data
    })
    .then((res) => {
        if(response !== undefined) response(res);
        if(res.status === 200) {
            return true;
        }
        else{
            return false;
        }
    })
    .catch((error) => {
        console.error("Sending to server failed. The server might be offline.\nTarget: " + target + "\nMessage: " + error.message);
        return false;
    });
    return successful;
}

export async function RequestFromServer(source, setVal, auth = undefined) {
    axios({
        method: "get",
        url: server_url + source,
        headers: {
            Authorization: 'Bearer ' + auth
        }
    })
        .then((response) => {
            // handle success
            setVal(response.data);
        })
        .catch((error) => {
            // handle error
            if(error.response.status === 403) console.warn("Request returned #403 Forbidden");
            else console.error(error);
        });
}