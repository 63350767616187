import { LoadHandler, SubTitle, Title } from "../Components/Components.js";
import { strArrayToText } from "../NonJSX/DataHandler.js";
import { useGetJSON } from "../NonJSX/DataHandler.js";

import '../../CSS/Pages/Impressum.css';


/**
 * Generates the body of the Impressum-Webpage
 * @returns The page-design as JSX
 */
export default function Impressum(){
    document.title = "Impressum - FBG Bremen";

    const data = useGetJSON("Data/impressum.json");

    return (
        <div id="impressum">
            <Title title="Impressum"/>
            
            <div className="content-field">
                <LoadHandler data={data}>
                    {
                        data !== undefined &&
                        <>
                            <Report data={data}/>
                            <FotoQuellen sources={data.sources}/>
                            <Laws laws={data.laws}/>
                        </>
                    }
                </LoadHandler>
            </div>
        </div>
    );
}

/**
 * Generates the impressum-content
 * @returns The object as JSX
 */
function Report(props){
    return(
        <>
            <SubTitle title="Angaben gemäss § 5 TMG"/>
            <div className="report">
                <Entry label="Name" info={props.data.name}/>
                <Entry label="Addresse" info={props.data.address}/>
                <Entry label="Vertreten durch" info={props.data.vorstehend}/>
                <Entry label="Kontakt" info={["E-Mail: " + props.data.contact.email, "Telefon: " + props.data.contact.telephone]}/>
                <Entry label="Registereintrag" info={["Eintragung im Vereinsregister", "ID: " + props.data.register.id, "Registergericht: " + props.data.register.authority]}/>
                <Entry label="Steuernummer" info={["ID: " + props.data.taxnumber.id, "Amt: " + props.data.taxnumber.authority]}/>
                <Entry label="Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV" info={props.data.accountable}/>
            </div>
        </>
    )
}

/**
 * 
 * @param {string} props.label The title of the entry
 * @param {*} props.info Either a string or an array of string holding the data of that entry
 * @returns 
 */
function Entry(props){
    return(
        <div className="entry">
            <p className="label">{props.label}</p>
            {
                typeof props.info === "string" &&
                <p className="info">{props.info}</p>
            }
            {
                typeof props.info === "object" &&
                props.info.map((info) => {
                    return <p key={info} className="info">{info}</p>;
                })
            }
        </div>
    );
}


function FotoQuellen(props){
    if(props.source === undefined){
        return <></>;
    }
    return (
        <div className="sources">
            <SubTitle title={props.sources.title}/>
            <p className="content">{strArrayToText(props.sources.content)}</p>
        </div>
    );
}

function Laws(props){
    return (
        <div className="laws">
            {props.laws.map((law)=> {
                return <Law key={law.title} title={law.title} content={law.content}/>
            })}
        </div>
    );
}

function Law(props){
    return (
        <div className="law">
            <SubTitle title={props.title}/>
            <p className="content">{strArrayToText(props.content)}</p>
        </div>
    );
}
